import { gql } from '@apollo/client';

export const GET_SLIDER_MOBILE_GQL = gql`
    query getSliderMobile {
        getSlider(slider_name: "homepage-mobile-slider") {
            id
            slider_name
            display_from
            display_to
            items {
                id
                banner_type
                html
            }
        }
    }
`;

export const GET_SLIDER_GQL = gql`
    query getSlider {
        getSlider(slider_name: "Homepage Slider") {
            id
            slider_name
            display_from
            display_to
            items {
                id
                banner_type
                html
            }
        }
    }
`;
export const QUERY_GET_CMS_BLOCKS = gql`
    query getCmsBlocks($identifiers: [String]!) {
        cmsBlocks(identifiers: $identifiers) {
            items {
                identifier
                title
                content
            }
        }
    }
`;
export const QUERY_OPTIMISTIC_RECENTLY_PRODUCT_GQL = gql`
    query Products($recentViewSKU: [String]) {
        products(filter: { sku: { in: $recentViewSKU } }, pageSize: 10) {
            total_count
            items {
                id
                sku
                name
                url_key
                thumbnail {
                    url
                    label
                }
                media_gallery {
                    url
                    label
                }
                categories {
                    name
                }
                price_range {
                    minimum_price {
                        discount {
                            amount_off
                            percent_off
                        }
                        final_price {
                            value
                            currency
                        }
                    }
                }
            }
        }
    }
`;
export const QUERY_GET_ACTUAL_PRODUCT = gql`
    query products($skus: [String!]!, $pageSize: Int = 20) {
        products(filter: { sku: { in: $skus } }, pageSize: $pageSize) {
            items {
                id
                catalogRule {
                    expire_date
                }
                thumbnail {
                    url
                    label
                }
                small_image {
                    url
                    label
                }
                media_gallery {
                    url
                    label
                }
                price_range {
                    minimum_price {
                        discount {
                            amount_off
                            percent_off
                        }
                        regular_price {
                            value
                            currency
                        }
                        final_price {
                            value
                            currency
                        }
                    }
                }
                reviews(pageSize: 1, currentPage: 1) {
                    total_count
                    summary
                    summary_detail
                }
                ... on ConfigurableProduct {
                    variants {
                        product {
                            id
                            name
                            sku
                        }
                    }
                }
            }
        }
    }
`;
export const QUERY_OPTIMISTIC_BESTSELLERS = gql`
    query bestSellerProducts($currentPage: Int = 1, $pageSize: Int = 10) {
        bestSellerProducts(pageSize: $pageSize, currentPage: $currentPage, sort: { name: DESC }) {
            total_count
            page_info {
                page_size
                current_page
                total_pages
            }
            items {
                name
                sku
                id
                url_key
                thumbnail {
                    url
                    label
                }
                media_gallery {
                    url
                    label
                }
            }
        }
    }
`;
export const QUERY_OPTIMISTIC_POPULAR = gql`
    query popularProducts($currentPage: Int = 1, $pageSize: Int = 10) {
        popularProducts(pageSize: $pageSize, currentPage: $currentPage, sort: { name: DESC }) {
            total_count
            page_info {
                page_size
                current_page
                total_pages
            }
            items {
                name
                sku
                id
                url_key
                thumbnail {
                    url
                    label
                }
                small_image {
                    url
                    label
                }
                media_gallery {
                    url
                    label
                }
            }
        }
    }
`;
export const QUERY_OPTIMISTIC_TRENDING = gql`
    query trendingProducts($currentPage: Int = 1, $pageSize: Int = 10) {
        trendingProducts(pageSize: $pageSize, currentPage: $currentPage, sort: { name: DESC }) {
            total_count
            page_info {
                page_size
                current_page
                total_pages
            }
            items {
                name
                sku
                id
                url_key
                thumbnail {
                    url
                    label
                }
                media_gallery {
                    url
                    label
                }
                categories {
                    name
                }
                price_range {
                    minimum_price {
                        discount {
                            amount_off
                            percent_off
                        }
                        final_price {
                            value
                            currency
                        }
                    }
                }
            }
        }
    }
`;
export const QUERY_GET_ALL_REVIEWS = gql`
    query allReview($currentPage: Int = 1, $pageSize: Int = 10) {
        allReview(pageSize: $pageSize, currentPage: $currentPage) {
            page_info {
                page_size
                current_page
                total_pages
            }
            items {
                images
                product {
                    name
                    sku
                    url_key
                    price_range {
                        minimum_price {
                            regular_price {
                                value
                                currency
                            }
                            final_price {
                                value
                                currency
                            }
                        }
                        maximum_price {
                            regular_price {
                                value
                                currency
                            }
                            final_price {
                                value
                                currency
                            }
                        }
                    }
                    small_image {
                        url
                        label
                    }
                    ... on ConfigurableProduct {
                        variants {
                            product {
                                id
                                name
                                sku
                            }
                        }
                    }
                }
                summary
                text
                nickname
                created_at
                average_rating
            }
            summary
            summary_detail
            total_count
        }
    }
`;

export const QUERY_GET_ALL_REVIEWS_ONLY_IMG = gql`
    query allReview($currentPage: Int = 1, $pageSize: Int = 10, $images: Boolean) {
        allReview(pageSize: $pageSize, currentPage: $currentPage, filter: { images: $images }) {
            page_info {
                page_size
                current_page
                total_pages
            }
            items {
                images
                product {
                    name
                    sku
                    url_key
                    price_range {
                        minimum_price {
                            regular_price {
                                value
                                currency
                            }
                            final_price {
                                value
                                currency
                            }
                        }
                        maximum_price {
                            regular_price {
                                value
                                currency
                            }
                            final_price {
                                value
                                currency
                            }
                        }
                    }
                    small_image {
                        url
                        label
                    }
                    ... on ConfigurableProduct {
                        variants {
                            product {
                                id
                                name
                                sku
                            }
                        }
                    }
                }
                summary
                text
                nickname
                created_at
                average_rating
            }
            summary
            summary_detail
            total_count
        }
    }
`;

export const QUERY_SHORT_REVIEWS = gql`
    query allReview($currentPage: Int = 1, $pageSize: Int = 10) {
        allReview(pageSize: $pageSize, currentPage: $currentPage) {
            page_info {
                page_size
                current_page
                total_pages
            }
            total_count
            items {
                images
                product {
                    name
                    url_key
                    thumbnail {
                        url
                        label
                    }
                }
                text
                summary
                text
                nickname
                created_at
                average_rating
            }
        }
    }
`;
export const QUERY_GET_PRODUCT_RELATED_BY_SKU_RECENTLY = gql`
    query relatedProduct($sku: String!, $code: String!) {
        relatedProduct(sku: $sku, code: $code) {
            name
            code
            items {
                sku
            }
        }
    }
`;
export const QUERY_GET_OFFER_FOR_U = gql`
    query relatedProduct($sku: String!, $code: String!) {
        relatedProduct(sku: $sku, code: $code) {
            name
            code
            items {
                sku
                name
                sku
                id
                url_key
                thumbnail {
                    url
                    label
                }
                media_gallery {
                    url
                    label
                }
            }
        }
    }
`;
export const GET_WISH_LIST = gql`
    query getWishList {
        customer {
            wishlist {
                id
                items_count
                sharing_code
                updated_at
                items_v2(currentPage: 1, pageSize: 200) {
                    page_info {
                        page_size
                        current_page
                    }
                    items {
                        id
                        added_at
                        product {
                            url_key
                            sku
                            name
                            __typename
                            price_range {
                                minimum_price {
                                    regular_price {
                                        value
                                        currency
                                    }
                                    final_price {
                                        value
                                        currency
                                    }
                                }
                            }
                            thumbnail {
                                url
                                label
                            }
                            ... on ConfigurableProduct {
                                variants {
                                    product {
                                        name
                                        sku
                                        price_range {
                                            minimum_price {
                                                regular_price {
                                                    value
                                                    currency
                                                }
                                                final_price {
                                                    value
                                                    currency
                                                }
                                            }
                                        }
                                        thumbnail {
                                            url
                                            label
                                        }
                                    }
                                    attributes {
                                        uid
                                        label
                                        code
                                        value_index
                                    }
                                }
                            }
                        }
                        ... on ConfigurableWishlistItem {
                            child_sku
                        }
                    }
                }
            }
        }
    }
`;
export const ADD_PRODUCT_TO_WISH_LIST = gql`
    mutation addProductsToWishlist($wishlistId: ID!, $sku: String!) {
        addProductsToWishlist(wishlistId: $wishlistId, wishlistItems: [{ sku: $sku, quantity: 1 }]) {
            wishlist {
                id
                items_count
                sharing_code
                updated_at
                items_v2(currentPage: 1, pageSize: 200) {
                    page_info {
                        page_size
                        current_page
                    }
                    items {
                        id
                        added_at
                        product {
                            url_key
                            sku
                            name
                            __typename
                            price_range {
                                minimum_price {
                                    regular_price {
                                        value
                                        currency
                                    }
                                    final_price {
                                        value
                                        currency
                                    }
                                }
                            }
                            thumbnail {
                                url
                                label
                            }
                            ... on ConfigurableProduct {
                                variants {
                                    product {
                                        name
                                        sku
                                        price_range {
                                            minimum_price {
                                                regular_price {
                                                    value
                                                    currency
                                                }
                                                final_price {
                                                    value
                                                    currency
                                                }
                                            }
                                        }
                                        thumbnail {
                                            url
                                            label
                                        }
                                    }
                                    attributes {
                                        uid
                                        label
                                        code
                                        value_index
                                    }
                                }
                            }
                        }
                        ... on ConfigurableWishlistItem {
                            child_sku
                        }
                    }
                }
            }
            user_errors {
                code
                message
            }
        }
    }
`;

export const REMOVE_PRODUCT_FROM_WISH_LIST = gql`
    mutation removeProductsFromWishlist($wishlistId: ID!, $id: ID!) {
        removeProductsFromWishlist(wishlistId: $wishlistId, wishlistItemsIds: [$id]) {
            wishlist {
                id
                items_count
                sharing_code
                updated_at
                items_v2(currentPage: 1, pageSize: 200) {
                    page_info {
                        page_size
                        current_page
                    }
                    items {
                        id
                        added_at
                        product {
                            url_key
                            sku
                            name
                            __typename
                            price_range {
                                minimum_price {
                                    regular_price {
                                        value
                                        currency
                                    }
                                    final_price {
                                        value
                                        currency
                                    }
                                }
                            }
                            thumbnail {
                                url
                                label
                            }
                            ... on ConfigurableProduct {
                                variants {
                                    product {
                                        name
                                        sku
                                        price_range {
                                            minimum_price {
                                                regular_price {
                                                    value
                                                    currency
                                                }
                                                final_price {
                                                    value
                                                    currency
                                                }
                                            }
                                        }
                                        thumbnail {
                                            url
                                            label
                                        }
                                    }
                                    attributes {
                                        uid
                                        label
                                        code
                                        value_index
                                    }
                                }
                            }
                        }
                        ... on ConfigurableWishlistItem {
                            child_sku
                        }
                    }
                }
            }
            user_errors {
                code
                message
            }
        }
    }
`;

export const GET_STORE_CONFIG_ON_DOCUMENT = gql`
    query storeConfig {
        storeConfig {
            frontend_config_favicon
            frontend_config_logo
            klaviyo_script
            klaviyo_enable
            live_chat_enable
            live_chat_script
            theme_select
            paypal_lib_url
        }
    }
`;

export const QUERY_GET_STORE_CONFIG_GQL = gql`
    query storeConfig {
        storeConfig {
            ##sociallogin_facebook_is_enabled
            ##sociallogin_facebook_app_id
            ##sociallogin_google_is_enabled
            ##sociallogin_google_app_id
            frontend_config_favicon
            frontend_config_logo
            twitter_enable
            facebook_enable
            pinterest_enable
            tracking_support_email
            store_notice_enable
            store_notice_message
            klaviyo_script
            klaviyo_enable
            live_chat_enable
            live_chat_script
            mail_enable
            compare_enable
            wishlist_enable
            blog_enable
            theme_select
            airwallex_mode
            step_discount_enable
            step_discount_config {
                discount
                subtotal
            }
            paypal_lib_url
            paypal_disable_funding
        }
    }
`;

export const QUERY_GET_MARKETING_CONFIG_GQL = gql`
    query {
        MarketingConfig {
            googleConversionTrackings {
                label
                value
            }
            facebookPixels {
                code
            }
            googleAnalytics {
                code
            }
            customTag
        }
    }
`;

export const GET_CUSTOM_TAG = gql`
    query {
        MarketingConfig {
            customTag
        }
    }
`;
export const GET_STORE_GQL = gql`
    query availableStores {
        availableStores(useCurrentGroup: true) {
            store_code
            store_name
            is_default_store
            store_group_code
        }
    }
`;

export const SUBSCRIBE_EMAIL_GQL = gql`
    mutation SubscribeEmailToNewsletter($email: String!) {
        subscribeEmailToNewsletter(email: $email) {
            status
        }
    }
`;

export const QUERY_GET_CUSTOM_FORM = gql`
    query customForm($id: Int!) {
        customForm(id: $id) {
            name
            fields {
                code
                title
                field_type
                is_required
                validator
                position
                note
                options {
                    key
                    value
                }
            }
        }
    }
`;

export const GET_CUSTOM_FORM_GQL = gql`
    mutation ($form_id: Int!, $fields: [FormRecordFieldInput]!, $fields_multi: [FormRecordFieldMultiInput]!) {
        createCustomFormRecord(input: { form_id: $form_id, fields: $fields, fields_multi: $fields_multi }) {
            result
        }
    }
`;
export const SAVE_KEYWORD = gql`
    mutation saveSearchKeyword($keyword: String!) {
        saveSearchKeyword(input: { keyword: $keyword })
    }
`;
